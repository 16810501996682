.header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    color: #1A0045;
}

.modalTitle{
    color: #1A0045;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 25px !important;
    padding-top: 20px 0;
    padding-left: 20px;
}

.tableText{
    color: #444445;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    font-size: 12px;
    padding-left: 20px;
}

.closeIcon {
    color: #1A0045;
    position: relative;
    top: 5px;
    right: -280px;
    font-size: 20px;
    cursor: pointer;
}

.customerName{
    padding: 20px 0;
    font-family: Poppins;
    color: #1A0045;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    padding-left: 20px;
    font-size: 20px !important;
}

.name{
    font-family: Poppins;
    color: #1A0045;
    /* color: #b5a796; */
    font-style: normal;
    /* font-weight: 500; */
    font-size: 20px;
}

.fosNames{
    width: 400px;
    padding-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    padding-left: 20px;
    font-size: 20px !important;
    color: #1A0045;
}

.fosDropdown{
    width: 250px;
    font-family: Poppins;
    position: absolute;
    bottom: 55px;
    left: 120px;
}

.footer{
    display: flex;
    font-family: Poppins;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 25px;
}

.errorMessage{
    color: #ff0000;
    font-size: 12px !important;
    letter-spacing: 0.5px;
    font-weight: bold !important;
}
