.pagenot{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.errorpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 9px 9px 50px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

.errortitle{
    font-size: 120px;
    color: #2D105E;
}

.errormessageone{
    text-transform: uppercase;
    font-weight: bold;
    color: #4E41D9;
    font-size: 35px;
    margin-top: -30px;
    font-family: Poppins;
}

.errormessagetwo{
    font-weight: bold;
    font-size: 20px;
    color: #4E41D9;
    margin-top: -30px;
    font-family: Poppins;
}
.home{
    font-weight: bold;
    font-size: 20px;
    color: #4E41D9;
    font-family: Poppins;
    /* text-decoration: underline; */
    border: 2px solid #4E41D9;
    padding: 7px 16px;
    margin-top: 10px;
}

.home:hover{
    color: #5e52dd;
    border: 2px solid #5e52dd;
}