.atlas-pagelayout {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: #ffffff;
}

.sidebar-wrap {
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    transition-property: all;
    transition-delay: 0s;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.sidebar-wrap.open {
    width: 300px;
}

.sidebar-wrap.close,
.ant-menu.ant-menu-inline-collapsed {
    width: 90px;
}

.logo-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.sidebar-wrap.open .logo-wrap {
    padding: 32px 32px 0 32px;
}

.sidebar-wrap.close .logo-wrap {
    padding: 8px;
}

.logo-wrap img {
    margin-bottom: 1.25rem;
}

.sidebar-wrap.close .logo-wrap img {
    height: 80px;
}

.logo-wrap p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    font-family: Poppins;

}

.username {
    color: #242424;
    font-size: 18px;
}

.sidebar-wrap.close .username {
    font-size: 16px;
}

.userrole {
    color: #454545;
    font-size: 14px;
}

button.double-arrow {
    display: block;
    cursor: pointer;
    background: none;
    border: none;
}

.menu-wraps ul {
    border: none;
}

.menu-wraps .menu-item {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
}

.menu-wraps .menu-item:hover {
    background: var(--tropkhk, linear-gradient(0deg, #292660 0%, #5C2562 21.77%, #9E2064 65.64%, #D91B5D 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #ffffff;
}

.menu-wraps .ant-menu-item-selected .menu-item span {
    font-weight: 700;
    background: var(--tropkhk, linear-gradient(0deg, #292660 0%, #5C2562 21.77%, #9E2064 65.64%, #D91B5D 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sidebar-wrap.close .menu-wraps .menu-item span {
    display: none;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
    /* background-image: url('../assets/images/selected-menu-icon.svg'); */
    border: none;
    /* width: 30px;
    height: 30px;
    top: 7px; */
}

/* Menu Tootip Style */
.ant-tooltip-inner {
    background-color: #F2F7FA;
}

.ant-tooltip-inner .menu-item,
.ant-menu-submenu-popup>.ant-menu>.ant-menu-item .menu-item {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
}

.ant-tooltip-inner .menu-item.active span,
.ant-tooltip-inner .menu-item:hover,
.ant-menu-submenu-popup>.ant-menu>.ant-menu-item .menu-item:hover {
    font-weight: 700;
    background: var(--tropkhk, linear-gradient(0deg, #292660 0%, #5C2562 21.77%, #9E2064 65.64%, #D91B5D 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Content area styes */
.content-wrap {
    background-color: #ffffff;
    overflow-y: auto;
    /* overflow-x: hidden; */
    position: relative;
    transition-property: all;
    transition-delay: 0s;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content-wrap.open {
    margin-left: 300px;
}

.content-wrap.close {
    margin-left: 90px;
}

.header-wrap {
    /* position: fixed;
    top: 0; */
    width: 100%;
}

.maincontent-wrap {
    border-radius: 16px;
    background: #F2F7FA;
    padding: 30px;
    min-height: calc(100vh - 100px);
    height: 100%;
}