.loanclosures-card .data-card-wrap{
    align-items: center;
    background: #ECD8FF;
}
.loanclosures-card .data-card-wrap .card{
    border-radius: 6px;
    padding: 10px;
    min-height: 60px;
    /* max-width: 157px; */
    border: 1px solid #957DAD;
    background: #ffffff;
}
.loanclosures-card .data-card-wrap .card.closure-count h3.closurecount {
    color: #420380;
}