.weeklyloans-card .data-card-wrap {
    background: #E1FFDE;
    align-items: center;
}

.weeklyloans-card .data-card-wrap .card {
    border-radius: 6px;
    padding: 10px;
    min-height: 60px;
    /* max-width: 157px; */
    border: 1px solid #B7F4B2;
    background: #ffffff;
}

.weeklyloans-card .data-card-wrap .card.loan-count h3.loancount {
    color: #129F07;
}