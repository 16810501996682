.sidebar-parent {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
}

.sidebar-inner-parent {
  height: 100%;
  background-color: #1A0045;
}

.sidebar-header-parent {
  height: 260px;
  border: none;
  color: #fff;
}

.sidebar-header-parent-collapsed {
  height: 70px;
  border: none;
  color: #fff;
}

.collapse-icon-parent {
  position: absolute;
  top: 10px;
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
}

.collapse-icon-parent-collapsed {
  position: absolute;
  top: 10px;
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
}

.logotext {
  padding-top: 10px;
  position: sticky;
  width: 100%;
}

.avatar {
  width: 100px !important;
  height: 110px !important;
  background-color: #FFFFFF !important;
  margin-left: auto;
  margin-right: auto;
}

#img {
  vertical-align: middle;
}

.logotext #partner {
  font-family: Red Rose;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.24em;
  text-align: center;
  color: #963460;
  position: relative;
  top: 20px;
}

.logotext #admin {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.08em;
  text-align: center;
  color: #FFFFFF;
}

.sidebar-content-parent {
  background-color: #2D105E;
  border-radius: 0 90px 0 0;
  overflow: auto;
  height: calc(100% - 260px);
}

.sidebar-content-parent-collapsed {
  background-color: #2D105E;
  /* border-radius: 0 90px 0 0; */
  overflow: auto;
  height: calc(100% - 70px);
}

.link {
  color: #ffffff !important;
}

.active {
  color: #e74c3c !important;
}

.sub-menu-items {
  margin-bottom: 6px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
  margin-top: -11px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 50px !important;
  background-color: #2D105E;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 1400px) {

  .sidebar-header-parent {
    height: 180px !important;
  }

  .sidebar-content-parent {
    background-color: #2D105E;
    border-radius: 0 90px 0 0;
    overflow: auto;
    height: calc(100% - 180px);
  }

  .sidebar-content-parent-collapsed {
    background-color: #2D105E;
    /* border-radius: 0 90px 0 0; */
    overflow: auto;
    height: calc(100% - 70px);
  }
  
  .avatar {
    width: 80px !important;
    height: 90px !important;
    background-color: #FFFFFF !important;
    margin-left: auto;
    margin-right: auto;
  }

  .logotext #partner {
    font-family: Red Rose;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.24em;
    text-align: center;
    color: #963460;
    position: relative;
    top: 1px;
  }
  
  .logotext #admin {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.08em;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    bottom: 35px;
  }
}
