.business-summary-seaction {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.business-summary-seaction .select-state.ant-select {
    max-width: 30%;
    width: 100%;
}

.business-summary-seaction .select-state.ant-select .ant-select-selector {
    border-radius: 8px;
    background: #F2F7FA;
    padding: 5px;
    border: none;
}

.business-summary-seaction .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    grid-auto-flow: row;
}

.business-summary-seaction .card-container .data-card-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
    grid-template-rows: 1fr;
    gap: 20px;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 10px;
}

.business-summary-seaction .card-container .data-card-wrap .filter-select .ant-select {
    width: 100%;
    font-size: 12px !important;

}

.total-revenue-section .revenue-cards-wrap .card.month-filter .select-month.ant-select {
    font-size: 12px !important;
}

.business-summary-seaction .card-container .data-card-wrap .filter-select .ant-select .ant-select-selector {
    border-radius: 6px;
    background: #FFF;
    border: none;
}

.business-summary-seaction .card-container .data-card-wrap .displayflex h4.card-title {
    color: #242424;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin: 0;
    word-break: break-word;
    max-width: 50%;
}

.business-summary-seaction .card-container .data-card-wrap .displayflex h3.card-title {
    color: #454544;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 2px;
    margin: 0;
    word-break: break-word;
}

.business-summary-seaction .card-container .data-card-wrap .displayflex h3.card-title .rupee {
    font-size: 12px;
}