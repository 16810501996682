.repayments-card .filter-select {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.repayments-card .select-month.ant-select {
    max-width: 30%;
    width: 100%;
}

.repayments-card .select-month.ant-select .ant-select-selector {
    border-radius: 8px;
    background: #F2F7FA;
    /* padding: 5px; */
    border: none;
}

.repayments-card .displayflexcol.data-card-wrap {
    max-width: 300px;
    width: 100%;
}

.repayments-card .data-card {
    border-radius: 10px;
    background: #FFF;
    gap: 0;
    width: 100%;
}

.repayments-card .data-card .displayflexcol {
    padding: 7px 10px;
    gap: 10px;
    border-radius: 0 0 10px 10px;
}

.repayments-card .data-card.disbursed .displayflexcol {
    border: 1px solid #A996BD;
}

.repayments-card .data-card.repaid .displayflexcol {
    border: 1px solid #D0A8F1;
}

.repayments-card .data-card.due .displayflexcol {
    border: 1px solid #E1BCFF;
}

.repayments-card .data-card .header {
    border-radius: 10px 10px 0 0;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #414141;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.9%;
    /* 19.026px */
    letter-spacing: 0.98px;
}

.repayments-card .data-card.disbursed .header {
    background: rgba(149, 125, 173, 0.81);
}

.repayments-card .data-card.repaid .header {
    background: #CB9EEF;
}

.repayments-card .data-card.due .header {
    background: #E5C5FF;
}

.repayments-card .data-card .label {
    color: #242424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.84px;
}

.repayments-card .data-card .value {
    color: #454544;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
}

.repayments-card .data-card .value .rupee {
    font-size: 18px;
}

.repayments-chart-wrap {
    flex-wrap: nowrap;
}

.repayments-chart-wrap .repayment-chart {
    width: 100%;
    gap: 10px;
    justify-content: center;
}

.repayments-chart-wrap .repayment-chart .recharts-tooltip-wrapper .custom-tooltip {
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.repayments-chart-wrap .repayment-chart .legend-wraper .legends {
    display: flex;
    align-items: center;
    gap: 10px;
}

.repayments-chart-wrap .repayment-chart .legend-wraper .leg-square {
    width: 30px;
    height: 30px;
}

.repayments-chart-wrap .repayment-chart .legend-wraper .leg-disbursed .leg-square {
    background: #957DAD;
}

.repayments-chart-wrap .repayment-chart .legend-wraper .leg-repaid .leg-square {
    background: #CB9EEF;
}

.repayments-chart-wrap .repayment-chart .legend-wraper .leg-due .leg-square {
    background: #E5C5FF;
}

.repayments-chart-wrap .repayment-chart .legend-wraper .leg-label {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dpd-card .head-area .chart-card-subtitle {
    color: #242424;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
    margin: 0;
}

.dpd-card .data-card {
    padding: 10px 20px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.17);
    min-width: 130px;
}

.dpd-card .data-card.thirty {
    border: 1px solid #2F61B3;
}

.dpd-card .data-card.sixty {
    border: 1px solid #B32F9E;
}

.dpd-card .data-card.ninety {
    border: 1px solid #51B32F;
}

.dpd-card .data-card .displayflexcol {
    gap: 20px;
}

.dpd-card .data-card .header {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dpd-card .data-card .content-area {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.dpd-card .data-card .content-area .tranches {
    color: #454544;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dpd-card .data-card .content-area .amount {
    color: #454544;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}

.dpd-card .data-card .content-area .amount .rupee {
    font-size: 18px;
}