.in-box{
    width: 100%;
    min-height: 200px;
    height: 100%;
    background-color: #ffffff;
    /* position: absolute;
    left: 65px;
    bottom: 85px; */
    border-radius: 16px;
    box-shadow: 9px 9px 50px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dotted #1A0045;
    margin: 20px 0;
    padding: 10px;
}
.in-box-success{
    background-color: #8cd38c;
    border: 1px solid #0f8f0f;
}
.in-box-error{
    background-color: #ec8e8e;
    border: 1px solid #b80808;
}

.modalHeader{
    text-align: center;
    font-size: 30px !important;
    font-family: Poppins;
    letter-spacing: 0.08em;
    color: #1A0045;
}

.closeIcon{
    position: absolute;
}

.modalSubHeader{
    text-align: center;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 21px;
}
.file-upload-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.custom-file-upload{
    width: 100%;
}
#file-upload{
    display: block;
    position: relative;
}

::file-selector-button {
    display: none;
  }

.uploadIcon{
    font-size: 125px;
    color: #1A0045;
}

.uploadedAlert{
    font-weight: bold;
    position: absolute;
    right: 11%;
    top: 32%;
    background-color: rgb(140, 211, 140);
    color: white;
    width: 500px;
    height: 210px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileTypeError{
    font-weight: bold;
    position: absolute;
    padding-left: 20px;
    right: 11%;
    top: 35%;
    background-color: rgb(236, 142, 142);
    color: white;
    width: 500px;
    height: 180px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.showLoader{
    display: block;
}

.hideLoader{
    display: none
}
