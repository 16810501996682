/*----------- Common Styles Here --------*/

.full-width {
    width: 100%;
}

.section-container {
    border-radius: 16px;
    background: #DBE6ED;
    padding: 20px;
    margin-bottom: 30px;
}

.section-title {
    color: #242424;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.68px;
    margin: 0;
}

.card-wrap {
    border-radius: 12px;
    background: #FFF;
    box-shadow: -3px 3px 50px 5px rgba(0, 0, 0, 0.12);
    padding: 25px;
}

.displayflexcol {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.displayflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.maintitle {
    color: #242424;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.68px;
}

.chart-card-title {
    color: #242424;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    margin: 0;
}

/* --------- Individual   styles --------- */