.login-page-wrap {
  background: url('../../assets/images/login-page-bg.png');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrap {
  border-radius: 16px;
  background: linear-gradient(142deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.45) 76.56%);
  box-shadow: 2px 2px 33px 4px rgba(51, 20, 34, 0.07);
  backdrop-filter: blur(7px);
  padding: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10rem;
  row-gap: 20px;
  flex-wrap: wrap;
  margin: 20px;
}

.login-form-wrap {
  border-radius: 8px;
  border: 1px solid #DEDEDE;
  min-height: 400px;
  min-width: 400px;
  padding: 30px;
}

.login-form-wrap .title-wrap {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  background: var(--tropkhk, linear-gradient(0deg, #292660 0%, #5C2562 21.77%, #9E2064 65.64%, #D91B5D 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
}

.login-form {
  width: 100%;
  margin: 30px 0;
}

.login-form .login-button {
  margin: 40px 0 15px !important;
  border-radius: 8px !important;
  background: #2F2661 !important;
  padding: 7px 0 !important;
}

.login-form .login-button:hover {
  background: #963460 !important;
}

.login-form .forgot-link {
  width: 100% !important;
  text-align: center !important;
}

.login-form .forgot-link .forgotlink {
  color: #963460 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-decoration: none !important;
}

.login-form .forgot-link .forgotlink:hover {
  color: #2F2661 !important;
}

@media only screen and (max-width: 1066px) {
  .login-wrap {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .login-wrap {
    padding: 20px !important;
    margin: 20px !important;
  }

  .login-wrap .img-wrap img {
    width: 150px !important;
  }

  .login-form-wrap {
    min-width: 100% !important;
  }
}

#forgot {
  color: #963460 !important;
  margin-left: 30px !important;
  margin-top: 10px !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.login-button {
  margin-top: 25px !important;
  margin-bottom: 15px !important;
  border-radius: 8px !important;
}