.selectdiv {
    position: relative;
    /* float: left; */
    width: 300px;
    /* margin-left: 425px; */
    box-shadow: 9px 9px 50px rgba(0, 0, 0, 0.12);
}

select::-ms-expand {
    display: none;
}

.selectdiv:after {
    content: '<>';
    font: 17px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    position: absolute;
    pointer-events: none;
}

.selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    max-width: 320px;
    /* height: 50px; */
    float: right;
    /* margin: 5px 0px;
    padding: 0px 24px; */
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    /* background-color: #ffffff; */
    background-image: none;
    border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
}
.action-wrap,.searchwrap{
    display: flex;
    align-items: flex-end;
    gap: 20px;
    justify-content: space-between;
}
.action-btn-wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
}
.searchwrap{
    flex-wrap: wrap;
}